import React from 'react'
import Layout from '../components/layout-2'
import Seo from '../components/seo'
import '@aws-amplify/ui-react/styles.css'
import image from '../images/itsme.jpg'
const Layout2 = () => {
  return (
    <Layout>
      <Seo title="About budgetsyncer" />

      <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white antialiased">
        <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
          <article
            className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue">
            <header className="mb-4 lg:mb-6 not-format">
              <address className="flex items-center mb-6 not-italic">
                <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                  <img className="mr-4 w-16 h-16 rounded-full"
                       src={image} alt="Tommi Taskinen" />
                  <div>
                    <a href="#" rel="author" className="text-xl font-bold text-gray-900">Tommi Taskinen</a>
                    <p className="text-base text-gray-500">Budgetsyncer creator</p>
                    <p className="text-base text-gray-500">
                      <time dateTime="2024-02-15" title="February 15th, 2024">Feb. 15, 2024</time>
                    </p>
                  </div>
                </div>
              </address>
              <h1
                className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">Introducing
                BudgetSyncer: Transform Your Financial Management Experience</h1>
            </header>
            <p className="lead">Are you tired of the manual grind involved in reconciling your bank transactions with
              your budget in YNAB? Have you been searching for a seamless solution to integrate your banking activities
              directly with your budgeting software? Look no further—BudgetSyncer is here to revolutionize your
              financial management process!</p>
            <p>As a dedicated YNAB user for over a decade, I've experienced firsthand how life-changing a well-managed
              budget can be. It's transformed my finances, providing clarity and control over my spending and saving
              habits. However, the constant need to manually reconcile bank transactions with YNAB was a time-consuming
              task that often led to delays and sometimes, inaccuracies in my budget. That's where BudgetSyncer makes a
              groundbreaking difference.</p>
            <h2>Why Choose BudgetSyncer?</h2>


            <p>Automated Transaction Importing: BudgetSyncer bridges the gap between your bank and YNAB, automating the
              transaction import process. No more manual entry! With transactions imported every hour, your budget stays
              up-to-date in real-time, giving you a precise snapshot of your finances at any moment.</p>
            <p>Comprehensive Bank Compatibility: Catering to a wide range of users across Europe, BudgetSyncer supports
              most European banks. This extensive compatibility ensures that no matter where you bank, you can connect
              your account to YNAB seamlessly, making it an invaluable tool for anyone looking to streamline their
              budgeting process.</p>
            <p>Time-Saving & Efficient: By automating the reconciliation process, BudgetSyncer saves you hours of manual
              work each month. This efficiency not only simplifies your financial management but also frees up your time
              for what truly matters to you.</p>
            <p>Enhanced Financial Accuracy: With hourly updates, BudgetSyncer ensures that your YNAB budget reflects
              your actual bank transactions with pinpoint accuracy. This real-time synchronization helps in making
              informed financial decisions, avoiding overdrafts, and planning for the future with confidence.</p>

            <p>Secure & Reliable: Your financial security is our top priority. BudgetSyncer uses state-of-the-art
              encryption and security protocols to ensure that your data is protected and your privacy is maintained at
              all times.</p>
            <p>User-Friendly: Designed with simplicity in mind, BudgetSyncer offers an intuitive interface that makes
              connecting your bank accounts to YNAB a breeze. Whether you're a tech-savvy individual or prefer
              straightforward solutions, you'll find BudgetSyncer easy to set up and use.</p>
            <h2>Transform Your YNAB Experience with BudgetSyncer</h2>

            <p>Embrace the future of financial management with BudgetSyncer. Say goodbye to the tedious task of manual
              reconciliation and hello to more time, accuracy, and peace of mind. As someone who's seen the profound
              impact of YNAB on my own finances, I can assure you that integrating BudgetSyncer into your financial
              toolkit will elevate your budgeting experience to new heights.</p>
            <p>Join the BudgetSyncer community today and take the first step towards a more efficient, accurate, and
              stress-free financial management journey. Your budget, and your future self, will thank you.</p>


          </article>
        </div>
      </main>

    </Layout>
  )
}

export default Layout2
